import {useQuery} from "react-query";
import React from "react";
import {IdRef, RankingEntry, GameRef} from "../../Models";
import ServiceClient from "../../ServiceClient";
import CocoroidIdComponent from "./CocoroidIdComponent";

export type Props = {
    scoreRef: GameRef,
    override?: Override,
}

export default function RankingComponent(props: Props) {
    const {scoreRef, override} = props
    const {isLoading, error, data} = useQuery(
        `getRanking(${JSON.stringify(scoreRef)})`,
        () => ServiceClient.getRanking(scoreRef))

    if (isLoading) return <p>loading...</p>
    if (error || !data) return <p>failed to load</p>
    if (data.length === 0) return <p>ランキングデータがありません</p>

    return (
        <ul>
            {data.map((e, i) => {
                const key = `${e.id.hostId},${e.id.guestId}`
                return <RankingItemComponent rankingEntry={e} index={i} override={override} key={key}/>
            })}
        </ul>
    )
}

type Override = {
    id: IdRef,
    score: number,
}

type ItemProps = {
    rankingEntry: RankingEntry,
    index: number,
    override?: Override,
}

function RankingItemComponent(props: ItemProps) {
    const {rankingEntry: {id, guest, host, score}, index, override} = props;
    const rank = index + 1
    const rankColor = getRankColorClass(rank)
    const self = ServiceClient.idRef
    const isSelf = id.hostId === self?.hostId && id.guestId === self?.guestId
    const selfColor = isSelf ? 'bg-red-100/50' : ''
    const overriddenScore = overrideScore(id, score, override)

    return (
        <div className={`flex items-center p-4 border-b-[0.5px] border-gray-300/50 last:border-b-0 ${selfColor}`}>
            <p className={`mr-24 ml-8 font-bold text-3xl w-16 ${rankColor}`}>{rank}</p>
            <div className='flex items-center justify-center mr-16 w-120'>
                <CocoroidIdComponent cocoroidIdDoc={host} iconSize={48} fontSize={10}/>
                <CocoroidIdComponent cocoroidIdDoc={guest} iconSize={48} fontSize={10}/>
            </div>
            <p className='font-semibold text-xl mw-[50px] text-gray-700'>
                {overriddenScore.toFixed(0)} pt
            </p>
        </div>)
}

function overrideScore(id: IdRef, score: number, override?: Override): number {
    if (id === override?.id && override.score > score) return override.score;
    return score;
}

function getRankColorClass(rank: number) {
    switch (rank) {
        case 1:
            return "text-[#FF9B3F]";
        case 2:
            return "text-[#3FBAFF]";
        case 3:
            return "text-[#D47C68]";
        default :
            return "text-[#616269]";
    }
}