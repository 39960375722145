import {CocoroidIdDoc} from "../../Models";
import React from "react";

export type Props = {
    cocoroidIdDoc: CocoroidIdDoc | undefined,
    iconSize: number,
    fontSize: number,
    classNames?: string | undefined,
}

export default function CocoroidIdComponent(props: Props) {
    const {cocoroidIdDoc, iconSize, fontSize, classNames} = props
    const {name, url} = cocoroidIdDoc ?? {}
    const fixedName = !!name ? name : 'no name'
    return (
        <div className={`flex-col justify-center items-center m-1 ${classNames ?? ''}`}>
            <div className={`rounded-full border-2`} style={{
                backgroundColor: "wheat",
                backgroundImage: `url(${url})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                height: `${iconSize}px`,
                width: `${iconSize}px`,
            }}/>
            <p className={`text-center truncate`} style={{
                width: `${iconSize}px`,
                fontSize: `${fontSize}px`,
            }}>
                {fixedName}
            </p>
        </div>
    )
}