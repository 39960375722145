import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {MobileLayout} from "./layout/MobileLayout";
import {DesktopLayout} from "./layout/DesktopLayout";
import {QueryClient, QueryClientProvider} from "react-query";
import App from "./react/App";
import ServiceClient from "./ServiceClient";
import {testMobile} from "./utils/Utils";
import {AirbrakeConnector} from "./AirbrakeConnector";
import LogReporter from "./LogReporter";

const layoutElement = document.getElementById('screen_layout') as HTMLElement
const layout = testMobile() ? new MobileLayout(layoutElement) : new DesktopLayout(layoutElement)
layout.initialize()
layout.update()

window.onresize = () => {
    layout.update()
}

window.onload = async () => {
    console.log('window.onload')

    LogReporter.overrideLogOutputs()

    await ServiceClient.initialize()
    AirbrakeConnector.initialize(ServiceClient.branch === "release")

    ReactDOM.render(
        <QueryClientProvider client={new QueryClient()}>
            <App/>
        </QueryClientProvider>,
        document.getElementById('root'))
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();