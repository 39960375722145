import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import moment from "moment";
import {DateSpan} from "./DateSpan";
import * as rx from "rxjs"
import {useLocation} from "react-router-dom";

export function forget(p: () => Promise<void>): () => void {
    return () => p().catch(console.error)
}

export function sleep(msec: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, msec))
}

export function waitUntil(intervalMs: number, f: (pastTimeMs: number) => boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        const startTime = Date.now()
        const handler = window.setInterval(onInterval, intervalMs)

        function onInterval() {
            try {
                if (f(Date.now() - startTime)) {
                    resolve()
                    window.clearInterval(handler)
                }
            } catch (e) {
                reject(e)
                window.clearInterval(handler)
            }
        }
    })
}

export function loadScript(path: string): Promise<void> {
    const js = document.createElement('script')
    js.type = 'text/javascript'
    js.src = path
    document.head.appendChild(js)
    return new Promise(resolve => js.onload = () => resolve());
}

export function testMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

export function testTouch(): boolean {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent)
}

export function testBrowserSupport(): boolean {
    console.log(`user agent: ${navigator.userAgent}`)
    const androidOsMatch = navigator.userAgent.match(/Android [0-9.]+/g) // don't use lookbehind on Safari
    if (!androidOsMatch) return true
    if (androidOsMatch.length == 0) return true

    const androidOsStr = androidOsMatch[0].substr(8) // removing "Android "
    console.log(`android os version: ${androidOsStr}`)

    const androidOs = parseFloat(androidOsStr)
    if (isNaN(androidOs)) return true

    console.log(`android os version (number): ${androidOs}`)

    return androidOs >= 9;
}

export function mergeStyles(propStyle: React.CSSProperties | undefined, ownStyle: React.CSSProperties): React.CSSProperties {
    if (propStyle) {
        return Object.assign(ownStyle, propStyle)
    } else {
        return ownStyle
    }
}

export function formatDateSpan(span: DateSpan) {
    const {start, end} = span
    return `${formatDate(start)} ~ ${formatDate(end)}`
}

function formatDate(date: Date) {
    return moment(date).format('YYYY.M.D')
}

export function createSilentAudioStream(): MediaStream {
    const context = new AudioContext()
    return context.createMediaStreamDestination().stream
}

export function tryJsonParse(text: string): any | undefined {
    try {
        return JSON.parse(text)
    } catch (e) {
        return undefined
    }
}

export function useObservable<T>(observable: rx.Observable<T>, defaultState: T, deps?: React.DependencyList | undefined): T {
    const [state, setState] = useState(defaultState)

    useLayoutEffect(() => {
        const s = observable.subscribe(v => setState(v))
        return () => s.unsubscribe()
    }, deps)

    return state
}

export function join(...paths: string[]): string {
    const separator = '/';
    const replace = new RegExp(separator + '{1,}', 'g');
    return paths.join(separator).replace(replace, separator);
}

export function useLocationPathEndsWith(p: string) {
    const location = useLocation()
    return location.pathname.endsWith(p)
}

export function makeRelativePath(p: string) {
    return `${join(window.location.pathname, p)}${window.location.search}`
}

export function useFillHeight<T extends HTMLElement>(ignoreSiblings: boolean = false) {
    const selfRef = useRef<T | null>(null)
    const {width, height} = useWindowResize()
    useEffect(() => {
        if (!selfRef.current) return
        const self = selfRef.current
        const height = getFillHeight(self, ignoreSiblings)
        self.style.height = `${height}px`
        //console.log(`fill height update: ${width}, ${height} -> ${height}, ${self.style.height}`)
    }, [selfRef, width, height, ignoreSiblings])

    return selfRef
}

function getFillHeight(self: HTMLElement, ignoreSiblings: boolean) {
    const selfMargin = getMarginY(self)

    const parent = self.parentElement!
    const parentInnerHeight = getInnerHeight(parent)
    //console.log(`useFillHeight parent inner height: ${parentInnerHeight}px`)

    let siblingOuterHeights = 0
    if (!ignoreSiblings) {
        for (let i = 0; i < parent.parentElement!.children.length; i++) {
            const sibling = parent.children.item(i) as HTMLElement
            if (!sibling) continue
            if (sibling === self) continue

            const styles = window.getComputedStyle(sibling)
            if (styles.position === 'absolute') continue
            if (styles.position === 'fixed') continue

            const outerHeight = getOuterHeight(sibling)
            siblingOuterHeights += outerHeight
            //console.log(`useFillHeight sibling outer height: ${outerHeight}px, style: ${styles.position}`)
        }
    }

    return parentInnerHeight - siblingOuterHeights - selfMargin
}

function getInnerHeight(element: HTMLElement) {
    return element.clientHeight - getPaddingY(element)
}

function getOuterHeight(element: HTMLElement) {
    const {height} = element.getBoundingClientRect()
    const margin = getMarginY(element)
    return height + margin
}

function getMarginY(element: HTMLElement) {
    const styles = window.getComputedStyle(element)
    const marginTop = parseFloat(styles.getPropertyValue('margin-top'))
    const marginBottom = parseFloat(styles.getPropertyValue('margin-bottom'))
    return marginTop + marginBottom
}

function getPaddingY(element: HTMLElement) {
    const style = window.getComputedStyle(element, null)
    const paddingTop = parseFloat(style.getPropertyValue("padding-top"))
    const paddingBottom = parseFloat(style.getPropertyValue("padding-bottom"))
    return paddingTop + paddingBottom
}

export function useSubject<T>(state: rx.BehaviorSubject<T>, keys: (keyof T)[]): T {
    const stream = state.pipe(
        rx.pairwise(),
        rx.filter(v => isChanged(keys, v)),
        rx.map(v => v[1]))

    return useObservable(stream, state.value, [])
}

export function isChanged<T>(keys: (keyof T)[], pair: T[]) {
    const [oldState, newState] = pair
    for (let key of keys) {
        const oldValue = oldState[key]
        const newValue = newState[key]
        if (oldValue !== newValue) {
            return true
        }
    }

    return false
}

export function useWindowResize(): { width: number, height: number } {
    function make() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    const obs = rx.fromEvent(window, 'resize').pipe(rx.map(make))
    return useObservable(obs, make(), [])
}