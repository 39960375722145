import HomePage from "./HomePage";
import GamePage from "./GamePage";
import React from "react";
import {BrowserRouter, Route, Switch, Redirect, useParams} from "react-router-dom";
import RankingPage from "./RankingPage";
import ServiceClient from "../ServiceClient";
import AdminPage from "./AdminPage";
import GameClient from "../GameClient";

export default function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path='/'>
                    <HomePage/>
                </Route>
                <Route path='/game/:id/:season'>
                    <GamePageRoute/>
                </Route>
                <Route path='/ranking/:game/:season'>
                    <RankingPageRoute/>
                </Route>
                <Route path='/admin'>
                    <AdminPage/>
                </Route>
                <Redirect to='/'/>
            </Switch>
        </BrowserRouter>
    )
}

function GamePageRoute() {
    const {id, season} = useParams<{ id: string, season: string }>();
    console.log(`GamePageRoute ${id}, ${season}`)

    if (!GameClient.initializeCalled) {
        GameClient.initialize(id, season).catch(console.error)
    }
    return <GamePage/>
}

function RankingPageRoute() {
    const {game, season} = useParams<{ game: string, season: string }>();
    console.log(`RankingPageRoute ${game}/${season}`)

    return <RankingPage scoreRef={{
        gameId: game,
        seasonId: season,
        branch: ServiceClient.branch,
    }}/>
}