import {GameDoc} from "../../Models";
import {Link} from "react-router-dom";
import React from "react";
import {FirebaseStorageImage} from "./FirebaseStorageImage";

export default function GameListItemComponent(props: { game: GameDoc, seasonId: string }) {
    const {game: {color, id, logo, title}, seasonId} = props;
    const path = `/game/${id}/${seasonId}${window.location.search}`
    return <Link to={path} className='block m-16 rounded-xl h-game_list_item relative overflow-hidden' style={{backgroundColor: color}}>
        {logo
            ? <FirebaseStorageImage path={logo} className='absolute m-auto inset-0 h-auto w-[60%]'/>
            : <p>{title}</p>
        }
    </Link>
}