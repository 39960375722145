import {useQuery} from "react-query";
import React, {Fragment} from "react";
import EventListItemComponent from "./components/EventListItemComponent";
import GameListItemComponent from "./components/GameListItemComponent";
import ServiceClient from "../ServiceClient";

export default function HomePage() {
    const {isLoading, error, data} = useQuery('getAllGames', () => ServiceClient.getAllGames())

    if (isLoading) return <p>loading</p>
    if (error) return <p>{JSON.stringify(error)}</p>
    if (!data) return <p>no data</p>

    const {eventGameInfos, gameInfos} = data

    return (
        <div className='overflow-y-scroll h-full flex flex-col'>
            {eventGameInfos.length > 0 &&
                <Fragment>
                    <h1 className='title'>🔥 ゲームイベント開催中</h1>
                    <div className='flex flex-col'>
                        {eventGameInfos.map(d => <EventListItemComponent game={d} key={d.game.id}/>)}
                    </div>
                </Fragment>
            }
            <h1 className='title'>🎮 すべてのゲーム</h1>
            <div className='flex-col'>
                {gameInfos.map((d, i) => <GameListItemComponent game={d.game} seasonId={d.seasonId} key={i}/>)}
            </div>
        </div>
    )
}

