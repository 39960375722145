import * as fs from "firebase/firestore"
import * as qs from 'query-string'

export type Branch = 'release' | 'staging' | 'develop'

export type GameDoc = {
    id: string,
    path: string,
    title?: string,
    hidden?: boolean,
    logo?: string,
    color?: string,
    about?: string,
}

export type EventDoc = {
    id: string,
    gameId: string,
    start?: fs.Timestamp,
    end?: fs.Timestamp,
    thumbnail?: string,
    hidden?: boolean,
    about?: string,
}

export type IdRef = {
    hostId: string,
    guestId: string,
}

export type GameRef = {
    gameId: string,
    seasonId: string,
    branch: Branch,
}

export type ScoreEntry = {
    id: IdRef,
    score: number,
}

export type GameAnalyticsDoc = {
    connection: number,
    play_count: number,
    playtime: number,
}

export const defaultGameAnalyticsDoc: GameAnalyticsDoc = {
    connection: 0,
    play_count: 0,
    playtime: 0,
}

export type GameInfo = {
    game: GameDoc,
    event?: EventDoc,
    seasonId: string, // fallback
}

type Schedule = {
    start?: fs.Timestamp,
    end?: fs.Timestamp,
    hidden?: boolean,
}


//todo move to util class
export function isClosed(schedule: Schedule) {
    const {start, end, hidden} = schedule
    const query = qs.parse(window.location.search)
    const forceOpen = !!query['forceOpen']
    console.log(`start: ${start}, end: ${end}, hidden: ${hidden}, forceOpen: ${forceOpen}`)
    if (forceOpen) return false
    if (hidden && hidden) return true

    const now = new Date()
    if (start && start.toDate() > now) return true
    if (end && end.toDate() < now) return true
    return false
}

export type CocoroidIdDoc = {
    name: string,
    url: string,
    is_tester?: boolean,
}

export type RankingEntry = {
    id: IdRef,
    host: CocoroidIdDoc,
    guest: CocoroidIdDoc,
    score: number,
}

export type DbRequest = {
    method: string,
    id: string,
    path: string | undefined,
    data: any | undefined,
    query: DbQuery | undefined,
    merge: boolean | undefined,
}

export type DbQuery = {
    orderBy: string | undefined,
    orderDirection: fs.OrderByDirection | undefined,
    limit: number | undefined,
}

export type LogType = 'error' | 'warn' | 'info'

export type LogEntry = {
    timestamp: Date,
    type: LogType,
    message: string,
}

export type LogReport = {
    timestamp: Date,
    hostId: string,
    guestId: string,
    urlQuery: string,
    gameId: string,
    seasonId: string,
    description: string,
    expectation: string,
    condition: string,
    initialLogs: LogEntry[],
    latestLogs: LogEntry[],
}