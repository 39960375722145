export class MobileLayout {
    root: HTMLElement

    constructor(root: HTMLElement) {
        this.root = root
    }

    initialize() {
        // viewport meta
        const meta = document.createElement('meta') as HTMLMetaElement
        meta.name = 'viewport'
        meta.content = 'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes'
        document.getElementsByTagName('head')[0].appendChild(meta)

        // stupid safari
        setInterval(() => this.update(), 500)
    }

    update() {
        this.root.style.width = window.innerWidth + 'px'
        this.root.style.height = window.innerHeight + 'px'

        const base = Math.min(window.innerWidth, window.innerHeight)
        const fontSizePx = (base / 375) * 12
        document.body.style.fontSize = `${fontSizePx}px`
    }
}