import {IdRef, CocoroidIdDoc, Branch} from "./Models";
import axios from "axios";

class CocoroidAWS {
    async searchCastsAndGuests(branch: Branch, ids: IdRef[]): Promise<{ [id: string]: CocoroidIdDoc }> {
        try {
            const host = this.getHostUrl(branch)
            console.log(`searchCastsAndGuests(${host}, ${JSON.stringify(ids)})`)
            const {casts, users} = await CocoroidAWS.api(host, ids.map(i => i.hostId), ids.map(i => i.guestId))
            const result = {...casts, ...users}
            console.log(`casts/guests result: ${JSON.stringify(result)}`)
            return result
        } catch (e) {
            console.error(e)
            const casts = Object.fromEntries(ids.map(i => [i.hostId, {name: "", url: ""}]))
            const users = Object.fromEntries(ids.map(i => [i.guestId, {name: "", url: ""}]))
            return {...casts, ...users}
        }
    }

    private static async api(host: string, hostIds: string[], guestIds: string[]) {
        const response = await axios.post(`https://${host}/api/SearchUsersAndCasts`, {
            'method': "SearchUsersAndCasts",
            'params': {
                'cast_ids': hostIds,
                'user_ids': guestIds,
            },
            'jsonrpc': "2.0",
            'id': "oapushjgfpouahjdf",
        })

        const casts = response.data['result']['casts'] as { [id: string]: CocoroidIdDoc }
        const users = response.data['result']['users'] as { [id: string]: CocoroidIdDoc }
        return {casts, users}
    }

    getHostUrl(branch: Branch) {
        switch (branch) {
            case "release": return "cocoroid.live"
            case "staging": return "cocoroid.link"
            case "develop": return "cocoroid.link"
        }
    }
}

export default new CocoroidAWS()
