import AdminLoginComponent from "./components/AdminLoginComponent";
import * as fb from "../Firebase"
import * as fs from "firebase/firestore"
import {useQuery} from "react-query";
import {EventDoc} from "../Models";
import {useSubject} from "../utils/Utils";
import {Fragment, useRef} from "react";
import ScoreCollector, {ScoreCollectorState} from "../ScoreCollector";
import EventCreator, {EventCreatorState} from "../EventCreator";

export default function AdminPage() {
    if (fb.isLoggedIn()) {
        return <AdminPageInternal/>
    } else {
        return <AdminLoginComponent/>
    }
}

function AdminPageInternal() {
    return <div className='flex flex-col p-16'>
        <h1 className='text-3xl'>管理者画面</h1>
        <div className='m-8 mb-16'>
            <h2 className='text-xl'>イベント設定</h2>
            <EventCreation/>
        </div>
        <div className='m-8 space-y-8 flex flex-col'>
            <h2 className='text-xl'>スコア集計</h2>
            <EventTable/>
            <EventScoreCollectButton/>
            <EventScoreTextArea/>
        </div>
    </div>
}

function EventCreation() {
    // form for event id (assert the format & identity)
    // form for game id (fetch the list from db)
    // form for "about" page (verify the destination)
    // form for start-end dates
    // form for thumbnail
    // button to submit
    // link to game/ranking pages

    const {data: events} = useQuery("getAllEvents", () => fb.getAllEvents())
    const {data: games} = useQuery("getAllGames", () => fb.getAllGames())
    const {data: thumbnailPaths} = useQuery('listStorageFiles', () => fb.listStorageFiles("games", '.png'))
    if (!events || !games || !thumbnailPaths) return <p>Loading events/games...</p>

    EventCreator.setExistingDocs(events, games, thumbnailPaths)

    return <div
        className='flex flex-col'>
        <EventIdForm/>
        <GameIdForm/>
        <AboutForm/>
        <ThumbnailForm/>
        <DateForm/>
        <SubmitButton/>
        <ResultURLs/>
    </div>
}

function EventIdForm() {
    const {eventId, eventIdVerified} = useEventCreatorState(["eventId", "eventIdVerified"])

    return <div
        className='flex items-center'
        style={{color: eventIdVerified ? "black" : "red"}}>
        <p className='w-[64px]'>Event ID:</p>
        <input
            type="text"
            value={eventId}
            className='border-2 m-4 '
            onChange={e => EventCreator.inputEventId(e.target.value)}/>
    </div>
}

function GameIdForm() {
    const {gameIdVerified} = useEventCreatorState(["gameIdVerified"])
    const gameIds = [""].concat(EventCreator.games.map(g => g.id))

    return <div
        className='flex items-center'
        style={{color: gameIdVerified ? "black" : "red"}}>
        <p className='w-[64px]'>Game ID:</p>
        <select
            className='border-2 m-4 '
            onChange={e => EventCreator.inputGameId(e.target.value)}>
            {gameIds.map(g => <option value={g} key={g}>{g}</option>)}
        </select>
    </div>
}

function AboutForm() {
    const {about, aboutVerified} = useEventCreatorState(["about", "aboutVerified"])

    return <div
        className='flex items-center'
        style={{color: aboutVerified ? "black" : "red"}}>
        <p className='w-[64px]'>About:</p>
        <input
            type="text"
            value={about}
            className='border-2 m-4 '
            onChange={e => EventCreator.inputAbout(e.target.value)}/>
    </div>
}

function ThumbnailForm() {
    const {thumbnailPathVerified} = useEventCreatorState(["thumbnailPathVerified"])
    const thumbnailPaths = [""].concat(EventCreator.thumbnailPaths)

    return <div
        className='flex items-center'
        style={{color: thumbnailPathVerified ? "black" : "red"}}>
        <p className='w-[64px]'>Thumbnail:</p>
        <select
            className='border-2 m-4'
            onChange={e => EventCreator.inputThumbnailPath(e.target.value)}>
            {thumbnailPaths.map(g => <option value={g} key={g}>{g}</option>)}
        </select>
    </div>
}

function DateForm() {
    const {dateVerified} = useEventCreatorState(["dateVerified"])

    return <Fragment>
        <div
            className='flex items-center'
            style={{color: dateVerified ? "black" : "red"}}>
            <p className='w-[64px]'>Start:</p>
            <input
                className='border-2 m-4'
                type='datetime-local'
                onChange={e => EventCreator.inputStartDate(e.target.value)}/>
        </div>
        <div
            className='flex items-center'
            style={{color: dateVerified ? "black" : "red"}}>
            <p className='w-[64px]'>End:</p>
            <input
                className='border-2 m-4'
                type='datetime-local'
                onChange={e => EventCreator.inputEndDate(e.target.value)}/>
        </div>
    </Fragment>
}

function SubmitButton() {
    const {canSubmit} = useEventCreatorState(["canSubmit"])

    return <button
        className='game-button-green'
        disabled={!canSubmit}
        onClick={() => EventCreator.submit()}
    >
        submit
    </button>
}

function ResultURLs() {
    const {
        submitError,
        gamePageUrl,
        rankingPageUrl
    } = useEventCreatorState(["submitError", "gamePageUrl", "rankingPageUrl"])

    if (submitError) {
        return <p style={{color: "red"}}>{submitError}</p>
    }

    return <div className='flex flex-col m-16 p-2 border-2 space-y-8'>
        <p>イベントを生成・公開しました.</p>
        <p>必ず動作確認をしてください.</p>
        <a href={gamePageUrl} target='_blank' rel='noreferrer' className='underline'>{gamePageUrl}</a>
        <a href={rankingPageUrl} target='_blank' rel='noreferrer' className='underline'>{rankingPageUrl}</a>
    </div>
}

function useEventCreatorState(keys: (keyof EventCreatorState)[]) {
    return useSubject(EventCreator.state, keys)
}

function EventTable() {
    const {data: events} = useQuery("getAllEvents", () => fb.getAllEvents())
    if (!events) return <p>Loading event table...</p>

    return <table>
        <thead>
        <tr>
            <th>eventId</th>
            <th>gameId</th>
            <th>hidden</th>
            <th>start</th>
            <th>end</th>
        </tr>
        </thead>
        <tbody>
        {events.map(e => <EventDocRow event={e} key={e.id}/>)}
        <GameRow/>
        </tbody>
    </table>
}

function EventScoreCollectButton() {
    const {canCollect, collectingScores} = useScoreCollectorState(["canCollect", "collectingScores"])

    return <button
        className='game-button-green m-4'
        disabled={!canCollect || collectingScores}
        onClick={() => ScoreCollector.collectScores()}>
        <p>{collectingScores
            ? 'collecting scores...'
            : 'collect scores'}
        </p>
    </button>
}

function EventScoreTextArea() {
    const {scoreText} = useScoreCollectorState(["scoreText"])
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    return <textarea
        ref={textareaRef}
        className='border-2'
        value={scoreText}
        onClick={onClick}/>

    function onClick() {
        textareaRef.current?.focus()
        textareaRef.current?.select()
    }
}

function EventDocRow(props: { event: EventDoc }) {
    const {event} = props
    const {eventId} = useScoreCollectorState(["eventId"])
    const isSelected = eventId === event.id

    return <tr
        onClick={() => ScoreCollector.selectEvent(event.id)}
        style={{backgroundColor: isSelected ? 'cyan' : 'white'}}>
        <td>{event.id}</td>
        <td>{event.gameId}</td>
        <td>{event.hidden ? 'o' : 'x'}</td>
        <td>{timestampToString(event.start)}</td>
        <td>{timestampToString(event.end)}</td>
    </tr>
}

function GameRow() {
    const {seasonId} = useScoreCollectorState(['seasonId'])
    const {isLoading, data} = useQuery('gameIds', fb.getAllGames)
    if (isLoading) return <tr/>
    if (!data) return <tr/>

    const gameIds = data.map(d => d.id)

    return <tr>
        <td>
            <textarea
                rows={1}
                className='border-2'
                value={seasonId}
                onChange={e => ScoreCollector.selectSeasonId(e.target.value)}/>
        </td>
        <td>
            <select
                className='border-2'
                onChange={e => ScoreCollector.selectGameId(e.target.value)}>
                {gameIds.map(g => <option value={g} key={g}>{g}</option>)}
            </select>
        </td>
    </tr>
}

function timestampToString(ts: fs.Timestamp | undefined): string {
    if (!ts) return ""
    const date = new Date(ts.seconds * 1000)
    const year = `${date.getFullYear()}`.substring(2)
    const month = date.getMonth() + 1
    return `'${year}/${month}/${date.getDate()}`
}

function useScoreCollectorState(keys: (keyof ScoreCollectorState)[]) {
    return useSubject(ScoreCollector.state, keys)
}