import {Subject} from "rxjs";
import {tryJsonParse} from "./utils/Utils";
import {DbRequest, GameRef, IdRef} from "./Models";
import * as fb from "./Firebase";
import * as rx from "rxjs"
import ServiceClient from "./ServiceClient";

export const defaultConfig = {
    musicVolume: 1,
    effectVolume: 1,
    volumeMagnitude: 1,
}

export type Config = typeof defaultConfig

export type AppConst = { version: string, horizontal: boolean }
export type Score = { score: number, increment: boolean }

class UnityConnector {
    gameFrame: HTMLIFrameElement
    isMaster: boolean = false
    idRef = {} as IdRef
    gameRef = {} as GameRef

    readonly onAppLoaded = new Subject<AppConst>()
    readonly onAppStarted = new Subject()
    readonly onPairConnected = new Subject()
    readonly onPairDisconnected = new Subject()
    readonly onGameStarted = new Subject()
    readonly onScoreUpload = new Subject<Score>()
    readonly onGameOver = new Subject()
    readonly onPhotonStateChanged = new Subject<string>()

    constructor() {
        console.log('UnityConnector.ctor()')
        this.gameFrame = document.getElementById('unity_iframe') as HTMLIFrameElement

        window.addEventListener("message", e => {
            const message = tryJsonParse(e.data)
            if (!message) return;

            const typeId = message["typeId"]
            switch (typeId) {
                case 'log': {
                    const logType = message["logType"] as string
                    const data = message["data"] as string
                    switch (logType) {
                        case 'error': {
                            console.error(data)
                            return
                        }
                        case 'warn': {
                            console.warn(data)
                            return
                        }
                        case 'log': {
                            console.log(data)
                            return
                        }
                        case 'info': {
                            console.info(data)
                            return
                        }
                        case 'debug': {
                            console.debug(data)
                            return
                        }
                    }
                    return
                }
                case 'appLoaded': {
                    const version = message['version'] ?? '1.0.0'
                    const horizontal = !!message['horizontal']
                    console.log(`app loaded; version: ${version}, horizontal: ${horizontal}`)
                    this.onAppLoaded.next({
                        version: version,
                        horizontal: horizontal,
                    })
                    return
                }
                case 'appStarted': {
                    console.log('app started')
                    this.onAppStarted.next(0)
                    return;
                }
                case 'connected': {
                    const masterStr = message['master']
                    this.isMaster = parseInt(masterStr) > 0
                    console.log(`connected; master: ${masterStr} -> ${this.isMaster}`)
                    this.onPairConnected.next(0)
                    return;
                }
                case 'disconnected': {
                    console.log('disconnected')
                    this.onPairDisconnected.next(0)
                    return;
                }
                case 'gameStarted': {
                    console.log('game started')
                    this.onGameStarted.next(0)

                    if (this.isMaster) {
                        fb.postGamePlayCountReport(this.gameRef, ServiceClient.idRef).catch(console.error)
                    }
                    return;
                }
                case 'uploadScore': {
                    this.handleScoreUploadMessage(message)
                    return
                }
                case 'gameOver': {
                    // we used to upload score via 'gameOver'
                    if (message['score']) {
                        console.log(`score upload (backward compatibility)`)
                        this.handleScoreUploadMessage(message)
                    }

                    console.log(`game over`)
                    this.onGameOver.next({})
                    return;
                }
                case 'stateChanged': {
                    const state = message["state"]
                    console.log(`state changed: ${state}`)
                    this.onPhotonStateChanged.next(state)
                    return
                }
                case 'db': {
                    const req = message['request']
                    console.log(`db request: ${req}`)
                    this.handleDbRequest(JSON.parse(req)).catch(console.error)
                    return
                }
                case 'gameAck': {
                    if (this.isMaster) {
                        fb.postGamePlaytimeReport(10, this.gameRef, ServiceClient.idRef).catch(console.error)
                    }
                    return
                }
                case 'connectionAck': {
                    if (this.isMaster) {
                        fb.postGameConnectionReport(10, this.gameRef, ServiceClient.idRef).catch(console.error)
                    }
                    return
                }
                default: {
                    console.log(`unknown message: ${JSON.stringify(e.data)}`)
                    return
                }
            }
        })
    }

    private handleScoreUploadMessage(message: any) {
        const scoreStr = message['score']
        const score = parseFloat(scoreStr)
        const incrementStr = message['increment']
        const increment = !!parseInt(incrementStr)
        console.log(`score: ${score}, increment: ${increment}`)
        this.onScoreUpload.next({score: score, increment: increment})
    }

    setUrl(url: string) {
        if (this.gameFrame.src !== url) {
            this.gameFrame.src = url
            console.log(`UnityConnector.setUrl(${url})`)
        }
    }

    init(id: string, name: string, profile: string, hostId: string, guestId: string) {
        console.log(`UnityConnector.init(${id}, ${name}, ${profile}, ${hostId}, ${guestId})`)
        this.postMessage('init', {
            'data': JSON.stringify({
                'id': id,
                'name': name,
                'profile': profile,
                'hostId': hostId,
                'guestId': guestId,
            })
        })
    }

    connect(roomName: string) {
        console.log(`UnityConnector.connect(${roomName})`)
        this.postMessage('connect', {"roomName": roomName})
    }

    startGame() {
        console.log(`UnityConnector.startGame()`)
        this.postMessage('startGame', {})
    }

    setConfig(config: Config) {
        this.postMessage('configure', {
            'config': JSON.stringify(config)
        })
    }

    setScore(score: number) {
        console.log(`UnityConnector.setScore(${score})`)
        this.postMessage('score', {
            'score': JSON.stringify(score),
        })
    }

    private dbObservables: { [id: string]: rx.Subscription | undefined } = {}

    private async handleDbRequest(req: DbRequest) {
        try {
            const {data, id, method, path, query, merge} = req;
            switch (method) {
                case "get": {
                    const data = await fb.dbGet(this.gameRef, this.idRef, path!)
                    this.dbRespond(id, data)
                    return
                }
                case "get_collection": {
                    const data = await fb.dbGetCollection(this.gameRef, this.idRef, path!, query!)
                    this.dbRespond(id, data)
                    return
                }
                case "set": {
                    await fb.dbSet(this.gameRef, this.idRef, path!, data!, merge ?? true)
                    this.dbRespond(id, 'success')
                    return
                }
                case "increment": {
                    await fb.dbIncrement(this.gameRef, this.idRef, path!, data!)
                    this.dbRespond(id, 'success')
                    return
                }
                case "subscribe": {
                    this.dbObservables[id] = fb.dbSubscribe(this.gameRef, this.idRef, path!).subscribe(data => this.dbRespond(id, data))
                    return
                }
                case "unsubscribe": {
                    this.dbObservables[id]?.unsubscribe()
                    this.dbObservables[id] = undefined
                    return
                }
                default: {
                    console.error(`unknown db method: ${req.method}`)
                    this.dbRespond(req.id, undefined, `unknown db method: ${req.method}`)
                    return
                }
            }
        } catch (e) {
            console.error(e)
            this.dbRespond(req.id, undefined, e)
        }
    }

    private dbRespond(id: string, data?: any, error?: unknown) {
        this.postMessage("db", {
            response: JSON.stringify({
                id: id,
                data: data,
                error: error,
            })
        })
    }

    private postMessage(typeId: string, data: { [key: string]: string }) {
        const load = {...data, typeId: typeId}
        this.gameFrame.contentWindow!.postMessage(JSON.stringify(load), "*")
    }
}

export default new UnityConnector()
