export class DesktopLayout {
    root: HTMLElement

    constructor(root: HTMLElement) {
        this.root = root
    }

    initialize() {
    }

    update() {
        const height = window.innerHeight;
        const width = height / 2;
        this.root.style.height = `${height}px`
        this.root.style.width = `${width}px`

        const fontSizePx = (width / 375) * 12
        document.body.style.fontSize = `${fontSizePx}px`
    }
}