import {Notifier} from '@airbrake/browser';

export namespace AirbrakeConnector {
    let _self: Notifier

    export function initialize(release: boolean) {
        _self = new Notifier({
            projectId: 378941,
            projectKey: '9a3ebd672f79eb9802555eacc9e913ed',
            environment: release ? 'production' : 'development',
        });

        // overrides stderr
        const console_error = console.error
        console.error = (...data: any[]) => {
            console_error(data)
            _self.notify(data).catch(console_error)
        }
    }
}