import {BehaviorSubject} from "rxjs";
import * as fb from "./Firebase"
import ServiceClient from "./ServiceClient";
import cocoroidAWS from "./CocoroidAWS";
import {defaultGameAnalyticsDoc} from "./Models";

export const defaultState = {
    eventId: undefined as string | undefined,
    seasonId: undefined as string | undefined,
    gameId: undefined as string | undefined,
    canCollect: false,
    collectingScores: false,
    scoreText: undefined as string | undefined,
}

export type ScoreCollectorState = (typeof defaultState)

class ScoreCollector {
    state = new BehaviorSubject<ScoreCollectorState>(defaultState)

    private modifyState(state: Partial<ScoreCollectorState>) {
        this.state.next({...this.state.value, ...state})
        //console.log(`GameClient.state: ${JSON.stringify(this.state.value)}`)
    }

    selectEvent(id: string) {
        this.modifyState({
            eventId: id,
            canCollect: true,
        })
    }

    selectSeasonId(id: string) {
        this.modifyState({
            seasonId: id,
            eventId: undefined,
            canCollect: !!this.state.value.gameId,
        })
    }

    selectGameId(id: string) {
        this.modifyState({
            gameId: id,
            eventId: undefined,
            canCollect: !!this.state.value.seasonId,
        })
    }

    async collectScores() {
        try {
            this.modifyState({collectingScores: true})

            const {eventId, gameId} = await this.getIds()

            const scoreRef = {
                gameId: gameId,
                seasonId: eventId,
                branch: ServiceClient.branch,
            }

            const analyticsRef = {
                gameId: gameId,
                seasonId: eventId,
                branch: ServiceClient.branch,
            }

            const scores = await fb.getScores(scoreRef, ServiceClient.branch === "develop")
            const analyticsDocs = await fb.getAnalytics(analyticsRef)
            const idDocs = await cocoroidAWS.searchCastsAndGuests(ServiceClient.branch, scores.map(s => s.id))
            const lines: string[] = []
            for (let score of scores) {
                const {hostId, guestId} = score.id
                const hostName = idDocs[hostId].name
                const guestName = idDocs[guestId].name
                const analytics = analyticsDocs[`${hostId},${guestId}`] ?? defaultGameAnalyticsDoc
                const {connection, playtime, play_count} = analytics
                lines.push(`${hostId}\t${guestId}\t${hostName}\t${guestName}\t${score.score}\t${playtime}\t${connection}\t${play_count}`)
            }
            const text = lines.join('\n')
            console.log(text)

            this.modifyState({scoreText: lines.join('\n')})
        } finally {
            this.modifyState({collectingScores: false})
        }
    }

    private async getIds() {
        const {seasonId, gameId} = this.state.value
        if (seasonId && gameId) {
            return {eventId: seasonId, gameId: gameId}
        } else {
            const eventId = this.state.value.eventId!
            const event = await fb.getEventDoc(eventId)

            if (!event) {
                throw new Error(`event not found: ${eventId}`)
            }

            return {eventId: eventId, gameId: event.gameId}
        }
    }
}

export default new ScoreCollector()