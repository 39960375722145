import React, {Fragment, useEffect, useState} from 'react';
import UnityConnector from "../UnityConnector";
import ServiceClient from "../ServiceClient";
import RankingComponent from "./components/RankingComponent";
import {FirebaseStorageImageBackground} from "./components/FirebaseStorageImage";
import CocoroidIdComponent from "./components/CocoroidIdComponent";
import GameClient, {GameState} from "../GameClient";
import {
    makeRelativePath,
    useLocationPathEndsWith,
    useObservable,
    useFillHeight,
    useSubject,
    useWindowResize,
} from "../utils/Utils";
import settingsIcon from '../resource/settings.png'
import reportIcon from '../resource/report.png'
import smartphone_landscape from '../resource/smartphone-landscape.svg'
import smartphone_portrait from '../resource/smartphone-portrait.svg'
import {Link} from "react-router-dom";
import LogReporter, {LogReporterState} from "../LogReporter";

export default function GamePage() {
    const {running, loaded, appConst} = useGameState(["running", "loaded", "appConst"])
    const version = appConst?.version ?? "1.0.0"
    const horizontal = appConst?.horizontal ?? false
    const showBugReportButton = ServiceClient.isHost && version >= "1.1.0"

    // fixed height for ranking
    const fixedHeight = useLocationPathEndsWith("/ranking")
    const fixedHeightClassNames = fixedHeight ? "absolute w-full h-full inset-0 m-auto" : ""

    // unity not loaded
    if (!loaded) return <Fragment/>

    // unity loaded but not running (not tapped on the screen)
    if (!running) return <Fragment>
        <div style={{pointerEvents: running ? 'all' : 'none'}}>
            <WaitingInput/>
        </div>
        <DeviceOrientationWarning requireHorizontal={horizontal}/>
    </Fragment>

    return <div className={`${fixedHeightClassNames} z-1 flex flex-col`}>
        <div className='flex absolute m-8 mb-0 z-10'>
            <button className='drop-shadow h-32 w-32 z-20' onClick={() => ServiceClient.openHomePage()}>
                <span className='text-white font-black text-lg'>x</span>
            </button>
            <div className='flex m-8'>
                <SettingsButton/>
            </div>
            {showBugReportButton &&
                <div className='flex m-8'>
                    <LogReportButton/>
                </div>
            }
        </div>
        <Switch/>
        <DeviceOrientationWarning requireHorizontal={horizontal}/>
    </div>
}

function DeviceOrientationWarning(props: { requireHorizontal: boolean }) {
    // device orientation
    const {requireHorizontal} = props
    const {width, height} = useWindowResize()
    const horizontal = width > height

    if (requireHorizontal === horizontal) return <Fragment/>

    return <div className='absolute inset-0 h-full w-full bg-black/50 flex flex-col items-center content-center justify-center'>
        <img src={horizontal ? smartphone_portrait : smartphone_landscape}/>
        <div className='text-white font-black text-lg text-center m-8'>
            {horizontal
                ? <span>スマートフォンを縦向きに<br/>してプレイしてください。</span>
                : <span>スマートフォンを横向きに<br/>してプレイしてください。</span>
            }
        </div>
    </div>
}

function WaitingInput() {
    const {logo} = useObservable(GameClient.game, GameClient.game.value, [])
    const {width, height} = useWindowResize()
    const vertical = height > width

    return <div className={`flex ${vertical ? 'flex-col' : 'flex-row'} items-stretch`}>
        <FirebaseStorageImageBackground path={logo} className={vertical ? 'h-100 m-32 mt-48' : 'w-1/2 h-100 m-auto'}/>
        <div className='bg-black/50 p-32 m-32'>
            <h1 className='font-semibold text-3xl text-white text-center'>
                画面をタップして<br/>ゲームスタート
            </h1>
            <h2 className='font-semibold text-xl text-white text-center mt-10'>
                イヤホンをしていない場合、<br/>
                ゲーム音声が聴こえづらいことがあります
            </h2>
        </div>
    </div>
}

function Switch() {
    const {initialized, failed, closed} = useGameState(["initialized", "failed", "closed"])
    const {playing, played} = useGameState(["playing", "played"])
    const showingRanking = useLocationPathEndsWith("/ranking")
    const showingSettings = useLocationPathEndsWith("/settings")
    const showingReport = useLocationPathEndsWith("/report")

    if (!initialized) return <p>loading game...</p>
    if (closed || failed) return <Closed/>
    if (showingSettings) return <Settings/>
    if (showingRanking) return <Ranking/>
    if (showingReport) return <LogReport/>
    if (playing) return <Fragment/>
    if (played) return <GameOver/>
    return <Front/>
}

function Closed() {
    return <div className='rounded-2xl bg-white flex-col'>
        <p>イベントが終了したか、読み込みに失敗しました。</p>
        <button className='btn-primary' onClick={() => ServiceClient.openHomePage()}>
            <span>ゲーム一覧に戻る</span>
        </button>
    </div>
}

function SettingsButton() {
    const showingSettings = useLocationPathEndsWith("/settings")

    return <div className={`flex flex-col justify-center items-center ${showingSettings ? 'hidden' : ''}`}>
        <Link to={makeRelativePath("/settings")}>
            <img alt='' src={settingsIcon} className='h-32 w-32 drop-shadow'/>
        </Link>
        <p className='text-white text-center leading-[20px] drop-shadow'>設定</p>
    </div>
}

function Settings() {
    const {effectVolume, musicVolume} = useGameState(["effectVolume", "musicVolume"])
    const {width, height} = useWindowResize()
    const vertical = height > width

    return <div className={`flex flex-col items-stretch m-48 self-center ${vertical ? '' : 'w-[500px]'}`}>
        <h1 className='text-2xl m-8 text-white drop-shadow font-black text-center'>設定</h1>
        <div className='flex flex-col justify-center items-center bg-white rounded-2xl m-8 p-16'>
            <div className='flex flex-col m-8 items-center'>
                <p>BGM音量</p>
                <input type='range' min='0' max='200' name='music' value={musicVolume * 100} onChange={onChange}/>
            </div>
            <div className='flex flex-col m-8 items-center'>
                <p>効果音音量</p>
                <input type='range' min='0' max='200' name='effect' value={effectVolume * 100} onChange={onChange}/>
            </div>
        </div>
        <Link to={makeRelativePath("/..")} className='game-button m-8 text-center'>
            <p className='text-lg font-semibold'>もどる</p>
        </Link>
    </div>

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = parseFloat(event.target.value) / 100
        //console.log(`config; ${event.target.name}: ${value}`)
        switch (event.target.name) {
            case 'music':
                GameClient.modifyConfig({musicVolume: value})
                return
            case 'effect':
                GameClient.modifyConfig({effectVolume: value})
                return
        }
    }
}

function LogReportButton() {
    const showingReport = useLocationPathEndsWith("/report")

    return <div className={`flex flex-col justify-center items-center ${showingReport ? 'hidden' : ''}`}>
        <Link to={makeRelativePath("/report")}>
            <img alt='' src={reportIcon} className='h-32 w-32 drop-shadow'/>
        </Link>
        <p className='text-white text-center leading-[20px] drop-shadow'>バグ報告</p>
    </div>
}

function LogReport() {
    useEffect(() => {
        LogReporter.initializeState()
    }, [])

    const {
        description,
        expectation,
        condition,
        entryCount,
    } = useLogReporterState(["description", "expectation", "condition", "entryCount"])
    type sendingType = 'idle' | 'sending' | 'success' | 'failed'
    const [sending, setSending] = useState('idle' as sendingType)

    return <div className='flex flex-col items-stretch m-48'>
        <div className='flex flex-col items-stretch justify-center bg-white rounded-2xl m-8 p-32 space-y-4 relative'>
            <h1 className='text-2xl m-8 drop-shadow font-black text-center'>バグ報告</h1>
            <textarea defaultValue={description}
                      onChange={e => LogReporter.setDescription(e.target.value)}
                      className='border-2'/>
            <textarea defaultValue={expectation}
                      onChange={e => LogReporter.setExpectation(e.target.value)}
                      className='border-2'/>
            <h3 className='text-xl m-8 drop-shadow font-black text-center mt-4'>発生条件</h3>
            <ConditionTextArea title='キャストID' value={ServiceClient.idRef.hostId}/>
            <ConditionTextArea title='ゲストID' value={ServiceClient.idRef.guestId}/>
            <ConditionTextArea title='URLパラメタ' value={window.location.search}/>
            <ConditionTextArea title='ログ' value={`${entryCount}件`}/>
            <textarea defaultValue={condition}
                      onChange={e => LogReporter.setCondition(e.target.value)}
                      className='border-2'/>
            <div className='flex flex-col space-y-16'>
                <button disabled={sending === 'sending' || sending === 'success'}
                        className='game-button-green h-32'
                        onClick={onClick}>
                    {buttonText()}
                </button>
                <Link to={makeRelativePath("/..")} className='game-button h-32'>
                    <p className='font-semibold text-lg text-center'>もどる</p>
                </Link>
            </div>
        </div>
    </div>

    function buttonText() {
        switch (sending) {
            case 'idle':
                return "報告する"
            case 'sending':
                return "送信中(しばらくかかります)..."
            case 'success':
                return "成功"
            case 'failed':
                return "失敗"
        }
    }

    function ConditionTextArea(props: { title: string, value: string }) {
        const {title, value} = props;
        return <div className='flex space-x-4 justify-center items-center'>
            <p className='w-[100px]'>{title}</p>
            <textarea value={value}
                      readOnly={true}
                      rows={1}
                      className='border-2 grow text-gray-400'
                      style={{resize: 'none'}}/>
        </div>
    }

    async function onClick() {
        try {
            setSending('sending')
            await LogReporter.sendReport()
            setSending('success')
        } catch (e) {
            setSending('failed')
        }
    }
}

function Front() {
    const {highestScore, about, loaded} = useGameState(["highestScore", "about", "loaded"])
    const {logo} = useObservable(GameClient.game, GameClient.game.value, [])
    const {width, height} = useWindowResize()
    const vertical = height > width

    return <div className={vertical ? 'flex flex-col' : 'flex flex-row'}>
        <FirebaseStorageImageBackground path={logo} className='h-100 w-1/2 m-auto'/>
        <div className='flex flex-col'>
            <div className='flex flex-col bg-gradient-to-b from-white to-white/75 rounded-3xl m-16 p-16 mb-0'>
                <div className='flex items-center justify-center'>
                    <CocoroidIdComponent cocoroidIdDoc={GameClient.host} iconSize={80} fontSize={12}
                                         classNames='m-12 mr-[12px]'/>
                    <CocoroidIdComponent cocoroidIdDoc={GameClient.guest} iconSize={80} fontSize={12}
                                         classNames='m-12'/>
                </div>
                <div className='flex items-center justify-center'>
                    <p className='leading-[1em]'>ただいまの<br/>ハイスコア</p>
                    <p className='text-4xl ml-16 font-bold text-gray-800'>
                        {highestScore?.toFixed(0) ?? '--'} pt
                    </p>
                </div>
            </div>
            <div className='flex flex-col m-8' hidden={!loaded}>
                <div className='flex m-4'>
                    <Link to={makeRelativePath("/ranking")}
                          className='game-button flex-grow m-4'>
                        <p className='font-semibold text-center'>ランキングを見る</p>
                    </Link>
                    <button className='game-button flex-grow m-4'
                            onClick={() => showAboutPage(about)}
                            disabled={!about}>
                        <p className='font-semibold'>このゲームについて</p>
                    </button>
                </div>
                <StartGameButton/>
            </div>
        </div>
    </div>
}

function StartGameButton() {
    const {paired, photon} = useGameState(["paired", "photon"])
    const invalidPair = ServiceClient.isInvalidPair()

    if (invalidPair) {
        return <p className='self-center m-8 text-center bg-white/50'>無効なペアIDです。<br/>アプリから接続しなおしてください。</p>
    }

    if (!paired) {
        return <p className='self-center m-8 text-center bg-white/50'>{photon}</p>
    }

    return (
        <button className='flex-grow m-8 p-16 rounded-3xl bg-white game-button-green'
                onClick={() => UnityConnector.startGame()}>
            <p className='font-bold text-4xl text-white'>START</p>
        </button>)
}

function Ranking() {
    const scrollParentRef = useFillHeight<HTMLDivElement>()
    return <div ref={scrollParentRef} className='flex flex-col items-stretch m-16'>
        <h1 className='text-4xl font-black text-white drop-shadow text-center m-8'>ランキング</h1>
        <div className='overflow-y-scroll bg-gradient-to-b from-white to-white/75 rounded-3xl m-8 p-24'>
            <RankingComponent scoreRef={GameClient.scoreRef}/>
        </div>
        <Link to={makeRelativePath("/..")} className='game-button m-8'>
            <p className='font-semibold text-lg text-center'>もどる</p>
        </Link>
    </div>
}

function GameOver() {
    const {lastScore} = useGameState(["lastScore"])
    const scoreOverride = {id: ServiceClient.idRef, score: lastScore}
    const {width, height} = useWindowResize()
    const vertical = height > width
    const scrollParentRef = useFillHeight<HTMLDivElement>(!vertical)

    return <div className={'absolute inset-0 h-full w-full p-16 pt-32 ' + (vertical ? '' : 'flex flex-row')}>
        <div className={'flex flex-col items-center justify-center ' + (vertical ? '' : 'w-1/2')}>
            <h1 className='m-8 drop-shadow text-white text-3xl'>GAME OVER</h1>
            <h1 className='text-white drop-shadow mt-8 text-lg'>ただいまのスコア</h1>
            <h1 className='text-white drop-shadow mb-8 text-5xl'>{lastScore.toFixed(0)} pt</h1>
        </div>
        <div ref={scrollParentRef} className='flex flex-col items-stretch justify-center m-8'>
            <div className='overflow-y-scroll bg-white rounded-2xl p-16 m-8 overflow-y-scroll'>
                <RankingComponent scoreRef={GameClient.scoreRef} override={scoreOverride}/>
            </div>
            <button className='game-button-green p-8 m-8'
                    onClick={() => UnityConnector.startGame()}>
                <p className='text-center font-semibold text-xl text-white'>
                    もういちど挑戦
                </p>
            </button>
        </div>
    </div>
}

function useGameState(keys: (keyof GameState)[]): GameState {
    return useSubject(GameClient.state, keys)
}

function useLogReporterState(keys: (keyof LogReporterState)[]): LogReporterState {
    return useSubject(LogReporter.state, keys)
}

export function showAboutPage(url: string | undefined) {
    console.log(`showAboutPage(${url})`)
    if (url) {
        window.open(url)
    }
}