import * as fb from "./Firebase";
import * as qs from 'query-string'
import {Branch, GameInfo, IdRef, isClosed, RankingEntry, GameRef, CocoroidIdDoc} from "./Models";
import CocoroidAWS from "./CocoroidAWS";

class ServiceClient {
    seasonId: string = "default"
    branch: Branch = "develop"
    idRef: IdRef = {hostId: "", guestId: ""}
    isHost: boolean = false

    async initialize() {
        const query = qs.parse(window.location.search)
        const hostId = query['cast'] as string ?? 'foo'
        const guestId = query['guest'] as string ?? 'bar'
        this.idRef = {hostId: hostId, guestId: guestId}
        this.isHost = !!query['isHost']
        this.seasonId = await fb.getSeasonId()
        this.branch = (query["branch"] as Branch) ?? getBranch()
        console.log(`ServiceClient.initialize() ${JSON.stringify(this.idRef)} ${this.seasonId}`)
    }

    async getMyIdDoc(): Promise<{ id: string, doc: CocoroidIdDoc }> {
        const id = this.isHost ? this.idRef.hostId : this.idRef.guestId
        const dic = await CocoroidAWS.searchCastsAndGuests(this.branch, [this.idRef])
        return {id: id, doc: dic[id] ?? {name: "", url: ""}}
    }

    async getAllGames() {
        const [eventDocs, gameDocs] = await Promise.all([fb.getAllEvents(), fb.getAllGames()])

        const eventGameInfos: GameInfo[] = []
        const gameDocsDir = Object.fromEntries(gameDocs.map(d => [d.id, d]))
        for (let eventDoc of eventDocs) {
            if (isClosed(eventDoc)) continue

            const gameDoc = gameDocsDir[eventDoc.gameId]
            if (!gameDoc) continue
            //if (isClosed(gameDoc)) continue

            eventGameInfos.push({
                game: gameDoc,
                event: eventDoc,
                seasonId: this.seasonId,
            })
        }

        const gameInfos: GameInfo[] = []
        for (let gameDoc of gameDocs) {
            if (isClosed(gameDoc)) continue

            gameInfos.push({
                game: gameDoc,
                seasonId: this.seasonId,
            })
        }

        return {eventGameInfos, gameInfos}
    }

    openHomePage() {
        const url = `${window.location.protocol}//${window.location.host}${window.location.search}`
        window.location.replace(url)
    }

    async getGameInfo(gameId: string, seasonId: string): Promise<GameInfo> {
        const gameDoc = await fb.getGameDoc(gameId)
        const eventDoc = await fb.getEventDoc(seasonId)
        return {
            game: gameDoc,
            event: eventDoc,
            seasonId: seasonId,
        }
    }

    async getRanking(scoreRef: GameRef): Promise<RankingEntry[]> {
        const scoreEntries = await fb.getScores(scoreRef, this.branch === "develop")
        console.log(`downloaded scores: ${JSON.stringify(scoreEntries)}`)

        if (scoreEntries.length === 0) return []

        const ids = await CocoroidAWS.searchCastsAndGuests(this.branch, scoreEntries.map(e => e.id))
        return scoreEntries.map(scoreEntry => {
            const {id: {guestId, hostId}, score} = scoreEntry;
            return {
                id: {hostId: hostId, guestId: guestId},
                host: ids[hostId],
                guest: ids[guestId],
                score: score,
            }
        })
    }

    isInvalidPair() {
        const {guestId, hostId} = this.idRef
        return guestId === "" && hostId === "" && this.branch === "release"
    }
}

export default new ServiceClient()

function getBranch(): Branch {
    switch (window.location.host) {
        case 'games.cocoroid.live':
            return "release"
        case 'games.cocoroid.link':
            return "staging"
        default:
            return "develop"
    }
}