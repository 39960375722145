import {GameRef} from "../Models";
import RankingComponent from "./components/RankingComponent";
import React, {Fragment, useRef} from "react";
import {useQuery} from "react-query";
import {FirebaseStorageImage} from "./components/FirebaseStorageImage";
import RightArrow from "../resource/chevron_right.png";
import {showAboutPage} from "./GamePage";
import ServiceClient from "../ServiceClient";

export default function RankingPage(props: { scoreRef: GameRef }) {
    const {scoreRef} = props;
    return <div className='absolute inset-0 w-full h-full m-auto flex flex-col'>
        <Header gameId={scoreRef.gameId} seasonId={scoreRef.seasonId}/>
        <h1 className='title'>👑 スコアランキング</h1>
        <div className='m-16 overflow-y-scroll h-full'>
            <RankingComponent scoreRef={scoreRef}/>
        </div>
    </div>
}

function Header(props: { gameId: string, seasonId: string }) {
    let {gameId, seasonId} = props;
    const imgDivRef = useRef<HTMLDivElement | null>(null)
    const {data} = useQuery(`getGameInfo(${gameId}, ${seasonId})`, () => ServiceClient.getGameInfo(gameId, seasonId))

    const game = data?.game
    const event = data?.event

    return <Fragment>
        <div ref={imgDivRef} className='h-game_list_item flex-grow flex' style={{
            backgroundColor: game?.color ?? '#555555',
        }}>
            <FirebaseStorageImage path={game?.logo} className='object-scale-down m-auto' style={{
                width: `${(imgDivRef?.current?.clientWidth ?? 0) - 10}px`,
                height: `${(imgDivRef?.current?.clientHeight ?? 0) - 10}px`,
            }}/>
        </div>
        <button
            onClick={() => showAboutPage(event?.about)}
            className='flex justify-center items-center h-[44px] min-h-[44px] mt-8 ml-8 mr-8 rounded-lg'
            style={{border: '1px solid rgba(48, 51, 75, 0.12)'}}>
            <div className="flex flex-row items-center justify-center">
                <h3 className='text-sm font-semibold'>ゲームイベントの詳細はこちら</h3>
                <img className="" src={RightArrow}/>
            </div>
        </button>
    </Fragment>
}