import {useState} from "react";
import * as fb from "../../Firebase"

export default function AdminLoginComponent() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    return (
        <div className='flex-row'>
            <div className='flex items-center'>
                <input
                    className='border-2 m-2'
                    type='text'
                    name='email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}/>
                <p>Email</p>
            </div>
            <div className='flex items-center'>
                <input
                    className='border-2 m-2'
                    type='password'
                    name='password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}/>
                <p>Password</p>
            </div>
            <button className='btn-primary' onClick={submit}>
                Submit
            </button>
            <p className='text-red-600'>{error}</p>
        </div>
    )

    async function submit() {
        try {
            console.log('submit')

            const c = await fb.login(email, password)
            console.log(`login: ${JSON.stringify(c)}`)

            window.location.reload()
        } catch (e) {
            setError(JSON.stringify(e))
        }
    }
}