export default class FifoArray<T> {
    private readonly elements: T[]
    private readonly capacity: number
    private pivot: number = 0 // next added index
    count: number = 0

    constructor(capacity: number) {
        this.capacity = capacity;
        this.elements = new Array(capacity)
        this.pivot = 0;
    }

    push(element: T) {
        this.elements[this.pivot] = element;

        this.pivot += 1;
        this.pivot %= this.capacity;

        this.count += 1;
        this.count = Math.min(this.count, this.capacity);
    }

    toArray(): T[] {
        const array: T[] = []
        for (let i = 0; i < this.count; i++) {
            const j = i % this.capacity
            const element = this.elements[j]
            array.push(element)
        }

        return array
    }
}