import {useQuery} from "react-query";
import * as fb from "../../Firebase";
import React from "react";

export type ImageProps = {
    path: string | undefined,
    className?: string | undefined,
    style?: React.CSSProperties | undefined,
}

export function FirebaseStorageImage(props: ImageProps) {
    const {path, className, style} = props;
    const url = useDownloadUrl(path) ?? ''
    return <img alt='' src={url} className={className ?? ''} style={style}/>
}

export type ImageBackgroundProps = {
    path: string | undefined,
    className?: string | undefined,
    style?: React.CSSProperties | undefined,
}

export function FirebaseStorageImageBackground(props: ImageBackgroundProps) {
    const {path, className, style} = props
    const url = useDownloadUrl(path) ?? ''
    return <div className={className} style={{
        ...style,
        backgroundImage: `url(${url})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
    }}/>
}

export function useDownloadUrl(path: string | undefined) {
    const {isLoading, error, data} = useQuery(`getDownloadUrl(${path})`, () => getDownloadUrl(path))
    return (isLoading || error || !data) ? undefined : data
}

async function getDownloadUrl(path: string | undefined) {
    if (path) {
        return await fb.getDownloadUrl(path)
    } else {
        return undefined
    }
}