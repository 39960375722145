import ServiceClient from "./ServiceClient";
import {CocoroidIdDoc, GameDoc, isClosed, GameRef} from "./Models";
import UnityConnector, {AppConst, Config, defaultConfig} from "./UnityConnector";
import * as fb from "./Firebase";
import {BehaviorSubject} from "rxjs";
import CocoroidAWS from "./CocoroidAWS";
import * as qs from 'query-string'
import {sleep} from "./utils/Utils";

export const defaultState = {
    initialized: false,
    failed: false,
    closed: false,
    loaded: false,
    running: false,
    paired: false,
    playing: false,
    played: false,
    photon: "",
    lastScore: 0,
    highestScore: 0,
    musicVolume: -1,
    effectVolume: -1,
    volumeMagnitude: -1,
    about: undefined as string | undefined,
    appConst: undefined as AppConst | undefined,
}

export type GameState = (typeof defaultState)

class GameClient {
    readonly game = new BehaviorSubject<GameDoc>({id: "", path: "none", title: ""})
    readonly state = new BehaviorSubject<GameState>(defaultState)
    initializeCalled = false
    gameId: string = ""
    host: CocoroidIdDoc | undefined
    guest: CocoroidIdDoc | undefined
    scoreRef: GameRef = {} as GameRef

    private modifyState(state: Partial<GameState>) {
        this.state.next({...this.state.value, ...state})
        //console.log(`GameClient.state: ${JSON.stringify(this.state.value)}`)
    }

    async initialize(gameId: string, seasonId: string) {
        console.log('GameClient.initialize()')
        if (this.initializeCalled) throw new Error('GameClient already initialized')
        this.initializeCalled = true

        this.gameId = gameId

        try {
            const {game, event} = await ServiceClient.getGameInfo(gameId, seasonId)
            this.game.next(game)
            if (event && isClosed(event)) {
                this.modifyState({closed: true})
                console.warn(`game closed: ${gameId}, aborting initialization`)
                return
            }

            this.modifyState({
                about: this.game.value.about
            })

            const {host, guest} = await fetchPairIdDocs()
            this.host = host
            this.guest = guest

            this.scoreRef = {
                gameId: gameId,
                seasonId: seasonId,
                branch: ServiceClient.branch,
            }

            this.fetchHighestScore().catch(console.error)

            const query = qs.parse(window.location.search)
            let unityConfigs: { [key: string]: any } = {...query}

            unityConfigs['branch'] = ServiceClient.branch

            // don't let them auto-start the game
            if (ServiceClient.isInvalidPair()) {
                unityConfigs = {}
            }

            const gameUrlBase = GameClient.getUnityUrlBase()
            const gameUrl = `https://${gameUrlBase}/game/${game.path}/index.html?${qs.stringify(unityConfigs)}`
            UnityConnector.setUrl(gameUrl)
            console.log(`game url: ${gameUrl}`)

            const {hostId, guestId} = ServiceClient.idRef
            const roomName = `${gameId}/${seasonId}/${ServiceClient.branch}/${hostId},${guestId}`
            console.log(`connected room name: ${roomName}`)

            UnityConnector.idRef = ServiceClient.idRef

            const gameRef = UnityConnector.gameRef = {
                gameId: this.game.value.id,
                seasonId: seasonId ?? ServiceClient.seasonId,
                branch: ServiceClient.branch,
            }

            console.log(`game ref: ${JSON.stringify(gameRef)}`)

            // url query to simulate the game over screen
            if (query['gameover']) {
                this.modifyState({
                    playing: false,
                    played: true,
                    lastScore: 100,
                })
            }

            UnityConnector.onAppLoaded.subscribe((appConst: AppConst) => {
                this.modifyState({loaded: true, appConst: appConst})
            })

            UnityConnector.onAppStarted.subscribe(async () => {
                let {id, doc: {name, url}} = await ServiceClient.getMyIdDoc();
                const {hostId, guestId} = ServiceClient.idRef;
                UnityConnector.init(id, name, url, hostId, guestId)
                UnityConnector.setConfig(this.state.value)
                UnityConnector.connect(roomName)
                this.modifyState({running: true})

                UnityConnector.setScore(this.state.value?.highestScore ?? 0)
                fb.observeScore(this.scoreRef, ServiceClient.idRef).subscribe(score => {
                    UnityConnector.setScore(score ?? 0)
                })
            })

            UnityConnector.onPairConnected.subscribe(() => {
                this.modifyState({paired: true})
            })

            UnityConnector.onPairDisconnected.subscribe(() => {
                this.modifyState({
                    paired: false,
                    playing: false,
                })
            })

            UnityConnector.onGameStarted.subscribe(() => {
                this.modifyState({playing: true})
            })

            UnityConnector.onScoreUpload.subscribe(async (s) => {
                const {score, increment} = s
                const lastScore = await fb.getScore(this.scoreRef, ServiceClient.idRef) ?? 0
                const newScore = increment ? Math.max(0, score + lastScore) : score
                console.log(`score: ${score}, increment: ${increment}, last score: ${lastScore}, newScore: ${newScore}`)

                this.modifyState({
                    lastScore: newScore,
                })

                if (UnityConnector.isMaster) {
                    if (!increment && newScore <= lastScore) {
                        console.log(`aborted updating db: score less than the existing score: ${newScore} <= ${lastScore}`)
                    } else {
                        await fb.postScore(newScore, ServiceClient.idRef, this.scoreRef)
                        console.log(`posted score: ${newScore}, idref: ${JSON.stringify(ServiceClient.idRef)}, scoreRef: ${JSON.stringify(this.scoreRef)}`)
                    }
                } else {
                    // don't fetch the score immediately because the master client is still uploading it
                    await sleep(500)
                }

                await this.fetchHighestScore()
            })

            UnityConnector.onGameOver.subscribe(async (s) => {
                this.modifyState({
                    played: true,
                    playing: false,
                })
            })

            UnityConnector.onPhotonStateChanged.subscribe(s => {
                this.modifyState({photon: s})
            })

            this.fetchDefaultConfigs().catch(console.error)

            console.log('GameClient.initialize() done')
        } catch (e) {
            console.error(e)
            this.modifyState({failed: true})
        } finally {
            this.modifyState({initialized: true})
        }
    }

    static getUnityUrlBase() {
        switch (ServiceClient.branch) {
            case "release": return "cocoroid.live"
            case "staging": return "cocoroid.link"
            case "develop": return "cocoroid.link"
        }
    }

    private async fetchHighestScore() {
        const score = await fb.getScore(this.scoreRef, ServiceClient.idRef) ?? 0
        this.modifyState({highestScore: score})
        console.log(`fetched highest score: ${score}, idref: ${JSON.stringify(ServiceClient.idRef)}, scoreRef: ${JSON.stringify(this.scoreRef)}`)
    }

    private async fetchDefaultConfigs() {
        const config = await fb.getDefaultGameConfig() ?? defaultConfig
        this.modifyConfig(config)
        console.log(`default config: ${JSON.stringify(config)}`)
    }

    modifyConfig(config: Partial<Config>) {
        this.modifyState(config)
        UnityConnector.setConfig(this.state.value)
    }
}

export default new GameClient()

async function fetchPairIdDocs() {
    const id = ServiceClient.idRef
    const map = await CocoroidAWS.searchCastsAndGuests(ServiceClient.branch, [id])
    return {host: map[id.hostId], guest: map[id.guestId]}
}