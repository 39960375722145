import * as fb from "firebase/app"
import * as fs from "firebase/firestore"
import * as fa from "firebase/auth"
import * as ft from 'firebase/storage'
import * as ff from 'firebase/functions'
import {GameDoc, IdRef, ScoreEntry, GameRef, EventDoc, GameAnalyticsDoc, DbQuery, LogReport} from './Models'
import {Config} from "./UnityConnector";
import * as rx from "rxjs"

const firebaseConfig = {
    apiKey: "AIzaSyDFyIFraokXpgMPHWLNlixgCf3tTC97sxs",
    authDomain: "virtualkanojo-0001.firebaseapp.com",
    databaseURL: "https://virtualkanojo-0001.firebaseio.com",
    projectId: "virtualkanojo-0001",
    storageBucket: "virtualkanojo-0001.appspot.com",
    messagingSenderId: "254851288930",
    appId: "1:254851288930:web:d7f8e96f6b1de8a98be63b",
    measurementId: "G-PNKX4GJL4Z"
}

const app = fb.initializeApp(firebaseConfig)
const firestore = fs.getFirestore(app)
const auth = fa.getAuth(app)
const storage = ft.getStorage(app)
const functions = ff.getFunctions(app)

const gamesPath = 'games'
const eventsPath = 'events'

function makeScoresPath(ref: GameRef) {
    return `scores/${ref.gameId}.${ref.branch}/${ref.seasonId}`
}

function makeIdPath(ref: IdRef) {
    return `${ref.hostId},${ref.guestId}`
}

function makeScorePath(scoreRef: GameRef, idRef: IdRef) {
    return `${makeScoresPath(scoreRef)}/${makeIdPath(idRef)}`
}

function makeGameAnalyticsRootPath(gameRef: GameRef) {
    return `analytics/${gameRef.gameId}.${gameRef.branch}/${gameRef.seasonId}`
}

function makeGameAnalyticsPath(gameRef: GameRef, pairId: IdRef) {
    return `${makeGameAnalyticsRootPath(gameRef)}/${makeIdPath(pairId)}`
}

export function isLoggedIn() {
    return !!auth.currentUser
}

export async function getDownloadUrl(path: string): Promise<string> {
    const ref = ft.ref(storage, path)
    return await ft.getDownloadURL(ref)
}

export async function listStorageFiles(path: string, ext: string): Promise<string[]> {
    console.log(`listStorageFiles(${path})`)
    const ref = ft.ref(storage, path)
    const list = await ft.listAll(ref)
    const paths = list.items.map(i => i.fullPath).filter(p => p.endsWith(ext))
    console.log(`listStorageFiles(${path}) -- ${paths.join(', ')}`)
    return paths
}

export async function login(email: string, password: string) {
    return await fa.signInWithEmailAndPassword(auth, email, password)
}

export async function getAllEvents() {
    console.log('getAllEvents()')
    const colRef = fs.collection(firestore, eventsPath)
    const col = await fs.query(colRef)
    const docs = await fs.getDocs(col)
    const eventDocs: EventDoc[] = []
    for (let doc of docs.docs) {
        const eventDoc = doc.data() as EventDoc
        eventDoc.id = doc.id // meh
        eventDocs.push(eventDoc)
        console.log(`getAllEvents(): ${JSON.stringify(eventDoc)}`)
    }
    return eventDocs
}

export async function getEventDoc(eventId: string): Promise<EventDoc | undefined> {
    console.log(`getEventDoc(${eventId})`)
    const path = `${eventsPath}/${eventId}`
    const docRef = fs.doc(firestore, path)
    const doc = await fs.getDoc(docRef)
    const eventDoc = doc.data() as EventDoc | undefined
    if (eventDoc) {
        eventDoc.id = doc.id
        console.log(`getEventDoc(${eventId}): ${JSON.stringify(eventDoc)}`)
        return eventDoc
    } else {
        console.log(`getEventDoc(${eventId}) failed; path: ${path}`)
    }
}

export async function createEvent(event: EventDoc) {
    console.log(`createEvent(${JSON.stringify(event)})`)
    const path = `${eventsPath}/${event.id}`
    const docRef = fs.doc(firestore, path)
    await fs.setDoc(docRef, event)
}

export async function getSeasonId() {
    const docRef = fs.doc(firestore, 'seasons/current')
    const doc = await fs.getDoc(docRef)
    return doc.get('id') as string
}

export async function getAllGames(): Promise<GameDoc[]> {
    console.log('getAllGames()')
    const colRef = fs.collection(firestore, gamesPath)
    const col = await fs.query(colRef)
    const docs = await fs.getDocs(col)
    const gameDocs: GameDoc[] = []
    for (let doc of docs.docs) {
        const gameDoc = doc.data() as GameDoc
        gameDoc.id = doc.id // meh
        gameDocs.push(gameDoc)
        console.log(`getAllGames(): ${JSON.stringify(gameDoc)}`)
    }
    return gameDocs
}

export async function getGameDoc(gameId: string): Promise<GameDoc> {
    console.log(`getGameDoc(${gameId})`)
    const docRef = fs.doc(firestore, `${gamesPath}/${gameId}`)
    const doc = await fs.getDoc(docRef)
    const gameDoc = doc.data() as GameDoc
    gameDoc.id = doc.id
    console.log(`getGameDoc(${gameId}): ${JSON.stringify(gameDoc)}`)
    return gameDoc
}

export async function postScore(score: number, idRef: IdRef, scoreRef: GameRef): Promise<boolean> {
    console.log(`postScore(${score}, ${JSON.stringify(scoreRef)}, ${JSON.stringify(idRef)})`)
    const path = `${makeScoresPath(scoreRef)}/${makeIdPath(idRef)}`
    const docRef = fs.doc(firestore, path)

    try {
        const data = {
            'score': score,
            'time': fs.Timestamp.now(),
        }
        await fs.setDoc(docRef, data, {merge: true})
        return true
    } catch (e) {
        if (e instanceof fb.FirebaseError && e.code === 'permission-denied') {
            console.log('permission denied')
            return false
        }

        throw e
    }
}

export async function getScores(scoreRef: GameRef, insertDummyData: boolean): Promise<ScoreEntry[]> {
    const path = makeScoresPath(scoreRef)
    console.log(`getScores(${JSON.stringify(scoreRef)}) - ${path}`)
    const colRef = fs.collection(firestore, path)
    const col = await fs.query(colRef, fs.orderBy('score', 'desc'))
    const docs = await fs.getDocs(col)
    const scoreEntries: ScoreEntry[] = []
    for (let doc of docs.docs) {
        const id = parsePairId(doc.id)
        const score = doc.get('score') as number
        const scoreEntry = {id: id, score: score}
        scoreEntries.push(scoreEntry)
        console.log(`getScores(): ${JSON.stringify(scoreEntry)}`)
    }

    if (insertDummyData) {
        for (let i = 0; i < 10; i++) {
            scoreEntries.push({
                id: {
                    hostId: `${i}`,
                    guestId: `${i}`,
                },
                score: i * 1000,
            })
        }
    }

    return scoreEntries
}

export async function getScore(scoreRef: GameRef, idRef: IdRef) {
    console.log(`getScore(${JSON.stringify(scoreRef)}, ${JSON.stringify(idRef)})`)
    const docRef = fs.doc(firestore, makeScorePath(scoreRef, idRef))
    const doc = await fs.getDoc(docRef)
    if (!doc.exists()) return undefined

    return doc.get('score') as number
}

export function observeScore(scoreRef: GameRef, idRef: IdRef): rx.Observable<number | undefined> {
    console.log(`observeScore(${JSON.stringify(scoreRef)}, ${JSON.stringify(idRef)})`)
    const docRef = fs.doc(firestore, makeScorePath(scoreRef, idRef))
    const observable = rx.fromEventPattern<fs.DocumentSnapshot>(
        handler => fs.onSnapshot(docRef, handler),
        (_, unsubscribe) => unsubscribe())

    return observable.pipe(rx.map(d => d.get("score")), rx.distinctUntilChanged())
}

export async function getDefaultGameConfig(): Promise<Config | undefined> {
    console.log('getDefaultGameConfig()')
    const docRef = fs.doc(firestore, 'configs/default')
    const doc = await fs.getDoc(docRef)
    if (!doc.exists()) return undefined
    return doc.data() as Config
}

function parsePairId(id: string): IdRef {
    const parts = id.split(',')
    return {hostId: parts[0], guestId: parts[1]}
}

export async function postGameConnectionReport(delta: number, gameRef: GameRef, pairId: IdRef) {
    //console.log(`postGameConnectionReport(${delta}, ${JSON.stringify(idRef)}, ${JSON.stringify(gameRef)})`)
    const path = `${makeGameAnalyticsPath(gameRef, pairId)}`
    const docRef = fs.doc(firestore, path)
    await fs.setDoc(docRef, {'connection': fs.increment(delta)}, {merge: true})
}

export async function postGamePlaytimeReport(delta: number, gameRef: GameRef, pairId: IdRef) {
    //console.log(`postGamePlaytimeReport(${delta}, ${JSON.stringify(idRef)}, ${JSON.stringify(gameRef)})`)
    const path = `${makeGameAnalyticsPath(gameRef, pairId)}`
    const docRef = fs.doc(firestore, path)
    await fs.setDoc(docRef, {'playtime': fs.increment(delta)}, {merge: true})
}

export async function postGamePlayCountReport(gameRef: GameRef, pairId: IdRef) {
    //console.log(`postGamePlayCountReport(${JSON.stringify(idRef)}, ${JSON.stringify(gameRef)})`)
    const path = `${makeGameAnalyticsPath(gameRef, pairId)}`
    const docRef = fs.doc(firestore, path)
    await fs.setDoc(docRef, {'play_count': fs.increment(1)}, {merge: true})
}

export async function getAnalytics(gameRef: GameRef) {
    console.log(`getAnalytics(${JSON.stringify(gameRef)})`)
    const path = makeGameAnalyticsRootPath(gameRef)
    console.log(path)
    const colRef = fs.collection(firestore, path)
    const col = await fs.query(colRef)
    const docs = await fs.getDocs(col)
    const analyticsEntries: { [id: string]: GameAnalyticsDoc } = {}
    for (let doc of docs.docs) {
        const data = doc.data() as GameAnalyticsDoc
        analyticsEntries[doc.id] = data
        console.log(`getAnalytics(): ${doc.id}, ${JSON.stringify(data)}`)
    }

    return analyticsEntries
}

function makeDbPath(gameRef: GameRef, idRef: IdRef, path: string) {
    const {branch, gameId, seasonId} = gameRef;
    const {hostId, guestId} = idRef;
    return `persistence/${gameId}.${branch}/${seasonId}/${hostId},${guestId}/${path}`
}

export async function dbGet(gameRef: GameRef, idRef: IdRef, path: string) {
    const docRef = fs.doc(firestore, makeDbPath(gameRef, idRef, path))
    const doc = await fs.getDoc(docRef)
    return doc.data() as any
}

export async function dbGetCollection(gameRef: GameRef, idRef: IdRef, path: string, query: DbQuery) {
    const {orderBy, orderDirection, limit} = query;
    const queries: fs.QueryConstraint[] = []
    if (orderBy) {
        queries.push(fs.orderBy(orderBy, orderDirection))
    }

    if (limit) {
        queries.push(fs.limit(limit))
    }

    const colRef = fs.collection(firestore, makeDbPath(gameRef, idRef, path))
    const col = await fs.query(colRef, ...queries)
    const docs = await fs.getDocs(col)
    return docs.docs.map(d => d.data() as any)
}

export async function dbSet(gameRef: GameRef, idRef: IdRef, path: string, data: any, merge: boolean) {
    const docRef = fs.doc(firestore, makeDbPath(gameRef, idRef, path))
    await fs.setDoc(docRef, data, {merge: merge})
}

export async function dbIncrement(gameRef: GameRef, idRef: IdRef, fieldPath: string, data: number) {
    const pathComps = fieldPath.split('/')
    const path = pathComps.slice(0, pathComps.length - 1).join("/")
    const docRef = fs.doc(firestore, makeDbPath(gameRef, idRef, path))
    const field = pathComps[pathComps.length - 1]
    await fs.setDoc(docRef, {[field]: fs.increment(data)}, {merge: true})
}

export function dbSubscribe(gameRef: GameRef, idRef: IdRef, path: string) {
    const docRef = fs.doc(firestore, makeDbPath(gameRef, idRef, path))
    return rx
        .fromEventPattern<fs.DocumentSnapshot>(
            handler => fs.onSnapshot(docRef, handler),
            (_, unsubscribe) => unsubscribe())
        .pipe(rx.map(d => d.data() as any), rx.distinctUntilChanged())
}

export async function sendReport(report: LogReport) {
    await ff.httpsCallable(functions, "receiveReport")(JSON.stringify(report))
}