import {BehaviorSubject} from "rxjs";
import * as fb from "./Firebase"
import * as fs from "firebase/firestore"
import {EventDoc, GameDoc} from "./Models";

export const defaultState = {
    eventId: "",
    gameId: "",
    eventIdVerified: false,
    gameIdVerified: false,
    about: "",
    aboutVerified: false,
    thumbnailPath: "",
    thumbnailPathVerified: false,
    startDate: null as Date | null,
    endDate: null as Date | null,
    dateVerified: false,
    canSubmit: false,
    submitError: " " as string | null,
    gamePageUrl: "",
    rankingPageUrl: "",
}

export type EventCreatorState = (typeof defaultState)

class EventCreator {
    state = new BehaviorSubject<EventCreatorState>(defaultState)
    events: EventDoc[] = []
    games: GameDoc[] = []
    thumbnailPaths: string[] = []

    private modifyState(state: Partial<EventCreatorState>) {
        this.state.next({...this.state.value, ...state})
        //console.log(`GameClient.state: ${JSON.stringify(this.state.value)}`)
    }

    setExistingDocs(events: EventDoc[], games: GameDoc[], filePaths: string[]) {
        this.events = events
        this.games = games
        this.thumbnailPaths = filePaths
    }

    inputEventId(id: string) {
        id = id.trim()

        this.modifyState({
            eventId: id,
            eventIdVerified: this.verifyEventId(id),
        })

        this.updateCanSubmit()
    }

    private verifyEventId(id: string): boolean {
        if (id === "") return false
        if (this.events.map(e => e.id).includes(id)) return false
        if (encodeURIComponent(id).includes('%')) return false
        return true
    }

    inputGameId(id: string) {
        this.modifyState({
            gameId: id,
            gameIdVerified: this.verifyGameId(id),
        })

        this.updateCanSubmit()
    }

    private verifyGameId(id: string): boolean {
        return this.games.map(e => e.id).includes(id)
    }

    private updateCanSubmit() {
        const state = this.state.value
        this.modifyState({
            canSubmit:
                state.eventIdVerified &&
                state.gameIdVerified &&
                state.thumbnailPathVerified &&
                state.dateVerified &&
                state.aboutVerified
        })
    }

    async submit() {
        const {
            canSubmit,
            eventId,
            gameId,
            about,
            thumbnailPath,
            startDate,
            endDate
        } = this.state.value

        if (!canSubmit || !startDate || !endDate) {
            console.error("submit button pressed but can't submit")
            return
        }

        try {
            await fb.createEvent({
                id: eventId,
                gameId: gameId,
                about: about,
                thumbnail: thumbnailPath,
                start: fs.Timestamp.fromDate(startDate),
                end: fs.Timestamp.fromDate(endDate),
            })
            this.modifyState({
                submitError: null,
                gamePageUrl: `https://games.cocoroid.live/game/${gameId}/${eventId}?forceOpen=1`,
                rankingPageUrl: `https://games.cocoroid.live/ranking/${gameId}/${eventId}?forceOpen=1`,
            })
        } catch (e) {
            this.modifyState({
                submitError: `${e}`,
            })
        }
    }

    inputAbout(about: string) {
        this.modifyState({
            about: about,
            aboutVerified: this.validateAbout(about),
        })
    }

    private validateAbout(about: string): boolean {
        return about.startsWith("https://")
    }

    inputThumbnailPath(thumbnailPath: string) {
        this.modifyState({
            thumbnailPath: thumbnailPath,
            thumbnailPathVerified: this.thumbnailPaths.includes(thumbnailPath),
        })
        this.updateCanSubmit()
    }

    inputStartDate(str: string) {
        const startDate = new Date(str)
        const {endDate} = this.state.value
        this.modifyState({
            startDate: startDate,
            dateVerified: !!endDate && startDate < endDate,
        })
        // console.log(`${startDate} ${endDate}`)
        this.updateCanSubmit()
    }

    inputEndDate(str: string) {
        const endDate = new Date(str)
        const {startDate} = this.state.value
        this.modifyState({
            endDate: endDate,
            dateVerified: !!startDate && startDate < endDate,
        })
        // console.log(`${startDate} ${endDate}`)
        this.updateCanSubmit()
    }
}

export default new EventCreator()