import {LogEntry, LogType} from "./Models";
import FifoArray from "./utils/FifoArray";
import {BehaviorSubject} from "rxjs";
import * as fb from "./Firebase";
import ServiceClient from "./ServiceClient";
import GameClient from "./GameClient";

export const defaultState = {
    description: "発生したバグの挙動",
    expectation: "本来期待される挙動",
    condition: "その他の発生条件",
    entryCount: 0,
}

export type LogReporterState = (typeof defaultState)

class LogReporter {
    readonly state = new BehaviorSubject<LogReporterState>(defaultState)
    private readonly initialEntries: LogEntry[] = []
    private readonly latestEntries = new FifoArray<LogEntry>(300)
    initialEntriesCopy: LogEntry[] = []
    latestEntriesCopy: LogEntry[] = []

    overrideLogOutputs() {
        this.override('error', "error")
        this.override('warn', "warn")
        this.override('log', "info")
        this.override('info', "info")
        this.override('debug', "info")
    }

    private override(name: 'error' | 'warn' | 'log' | 'info' | 'debug', type: LogType) {
        // @ts-ignore
        const originalFunc: ((...data: any[]) => void) = console[name]

        // @ts-ignore
        console[name] = (...data: any[]) => {
            const message = data.join().trim()

            originalFunc(message)

            // don't include "meta" logs
            if (message.startsWith('-x-')) return

            const entry: LogEntry = {
                timestamp: new Date(),
                type: type,
                message: message,
            }

            if (this.initialEntries.length < 200) {
                this.initialEntries.push(entry)
            } else {
                this.latestEntries.push(entry)
            }
        }
    }

    private modifyState(state: Partial<LogReporterState>) {
        this.state.next({...this.state.value, ...state})
    }

    initializeState() {
        this.initialEntriesCopy = [...this.initialEntries]
        this.latestEntriesCopy = this.latestEntries.toArray()
        this.modifyState({
            ...defaultState,
            entryCount: this.initialEntriesCopy.length + this.latestEntriesCopy.length,
        })
    }

    setDescription(value: string) {
        this.modifyState({
            description: value,
        })
    }

    setExpectation(value: string) {
        this.modifyState({
            expectation: value,
        })
    }

    setCondition(value: string) {
        this.modifyState({
            condition: value,
        })
    }

    async sendReport() {
        const report = {
            timestamp: new Date(),
            hostId: ServiceClient.idRef.hostId,
            guestId: ServiceClient.idRef.guestId,
            gameId: GameClient.scoreRef.gameId,
            seasonId: GameClient.scoreRef.seasonId,
            urlQuery: window.location.search,
            description: this.state.value.description,
            expectation: this.state.value.expectation,
            condition: this.state.value.condition,
        }

        console.log(`-x-${JSON.stringify(report)}`)

        await fb.sendReport(Object.assign(report, {
            initialLogs: this.initialEntriesCopy,
            latestLogs: this.latestEntriesCopy,
        }))
    }
}

export default new LogReporter()