import {Link} from "react-router-dom";
import React, {useRef} from "react";
import {GameInfo, isClosed} from "../../Models";
import {useDownloadUrl} from "./FirebaseStorageImage";

export default function EventListItemComponent(props: { game: GameInfo }) {
    const {game: {game, event}} = props;
    const thumbnailParentRef = useRef<HTMLAnchorElement | null>(null)

    if (!event) throw new Error("no event")

    const canClickIn = !isClosed(event);
    const path = `/game/${game.id}/${event.id}/${window.location.search}`;
    const pointer = canClickIn ? "" : "pointer-events-none";
    const thumbnail = useDownloadUrl(event.thumbnail);

    return (<Link ref={thumbnailParentRef} to={path} className={`block m-16 ${pointer}`}>
        <img alt={game.title} src={thumbnail} className='rounded-xl' style={{
            width: `${thumbnailParentRef.current?.clientWidth ?? 120}px`,
        }}/>
    </Link>);
}
